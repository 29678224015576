@import 'main.scss';

.error-pages {

    .row {
        padding: 12px;
    }

    .section {
        text-align: center;
        padding: 50px 15px;

        @include respond-to(sm) {
            padding: 100px 25px;
        }
        
        h1 {
            margin-bottom: 25px;
            line-height: 120%;
        }

        > p {
            margin-bottom: 10px;
            line-height: 120%;

            a {
                color: #000;
                text-decoration: underline;
            }
        }
    }

}